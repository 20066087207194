import EventEmitter from "@onemorestudio/eventemitterjs";
export default class ArchivList extends EventEmitter {
  constructor(item, i, factor, total) {
    super();
    this.div = document.createElement("div");
    this.div.className = "programm archiv";
    this.div.style.zIndex = total - i;
    this.div.style.transitionDelay = `${(total - i) / factor}s`;
    // const date = item["date"].split("-");
    this.div.innerHTML = `<div class="content" data-year="${i}"><ul data-year="${i}"><li data-year="${i}">${item}</li></ul></div>`;

    this.handler = {
      contentClick: this.onContentClick.bind(this),
    };

    this.initListener();
  }

  initListener() {
    const content = this.div.getElementsByClassName("content")[0];
    content.addEventListener("click", this.handler.contentClick);
  }
  onContentClick(e) {
    this.emit("click", [e]);
  }
}
