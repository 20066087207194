export default class RegularPage {
  constructor(data, len, i, factor) {
    console.log(data);
    console.log(data["programm_id"]);
    this.programm_id = data["programm_id"];
    this.div = document.createElement("div");
    this.div.className = "programm regular";
    this.div.style.zIndex = len - i;
    this.div.style.transitionDelay = `${(len - i) / factor}s`;
    this.div.style.backgroundColor = `${data["color"]}`;
    this.div.innerHTML = `<div class="content" >
                            <ul>
                               
                                <li>${data["titre"]}</li>
                            </ul>
                          <div class="short">
                            <div class="detail">
                             ${
                               this.programm_id == 38 || this.programm_id == 39
                                 ? this.specialContent(this.programm_id)
                                 : data["text_1"] + data["text_2"]
                             }
                            </div>
                          </div>
                      </div>`;
    this.detail = this.div.querySelector(".detail");
    console.log("detail", this.detail);
  }

  specialContent(id) {
    let content = "";
    switch (id) {
      case "38":
        content = `Mit folgendem Formular können Sie die Mitgliedschaft bei contrapunkt. new art music.
                    beantragen (bitte Art der Mitgliedschaft auswählen und ihre persönlichen Angaben ausfüllen) :<br><br/>
                    <form action="sendMail.php" method="post"  enctype="text/plain">
                    <label><input type="radio" value="50" name="type" checked><span>Einzelmitgliedschaft: Fr. 50.– pro Jahr</span></label><br/>
                    (Sie erhalten eine Ermässigung auf den Eintrittspreis von Fr. 10.- pro Konzert)<br/><br/>

                    <label><input type="radio" value="200" name="type"><span>Kollektivmitglied: Fr. 200.- pro Jahr</span></label><br/>
                    (Sie erhalten 5 Freikarten pro Jahr)<br><br/>
                    <label><input type="radio" value="500" name="type"><span>Gönnermitglied: Fr. 500.- pro Jahr</span></label><br/>
                    (Sie erhalten 10 Freikarten pro Jahr)<br>
                    <br>
                    Vorname*<input type="text" value="" name="vorname" ><br/>
                    Name*<input type="text" value="" name="name" ><br/>
                    Adresse*<input type="text" value="" name="adresse" ><br/>
                    Postleitzahl*<input type="text" value="" name="npa" ><br/>
                    Ort*<input type="text" value="" name="location" ><br/>
                    E-Mail*<input type="text" value="" name="email" ><br/>
                    (* nötige Angaben)
                    <input type="hidden" value="member" name="function">
                    <input type="submit" value="Antrag absenden" name="submit">
                    </form>`;
        break;
      case "39":
        content = `Abonnieren Sie den kostenlosen Newsletter von contrapunkt. new art music. und verpassen Sie keine Veranstaltung.`;
        // content += `<form method="post" action="https://newsletter.infomaniak.com/external/submit" class="inf-form" target="_blank"><input type="email" name="email" style="display:none" /><input type="hidden" name="key" value="eyJpdiI6Ink0NWI3NDNEcWZxSUNlU2xWcHV3MDQwd1RaaGljOVRIM2ZQTHp4SlE0V0k9IiwidmFsdWUiOiJwa0pRMDBLSHNodmM4OGR5dm5zcUkzOUZwMVUwVWFiVzJnQjhZV29pbmdNPSIsIm1hYyI6ImYyYzQzMDVlYzVlMTAzYzY2ZDhjNDdhOGRmMjllOTNjYjI0YjFmYTRlMjZjYmQxNGM3ZjZhYTliNDQ3N2JiNjUifQ=="><input type="hidden" name="webform_id" value="12954">
        // <div class="inf-main_ab96c631d50e81c5f961bcbf25e49475"> <h4></h4> <span></span> <div class="inf-success" style="display:none"> <h4>Ihre Anmeldung wurde erfolgreich gespeichert!</h4> <p> <a href="#" class="inf-btn">&laquo;</a> </p> </div> <div class="inf-content"> <div class="inf-input inf-input-text"> <label>Vorname*</label> <input type="text" name="inf[2]" data-inf-meta = "2" data-inf-error = "Bitte geben Sie eine Zeichenkette an" required="required" > </div> <div class="inf-input inf-input-text"> <label>Name*</label> <input type="text" name="inf[3]" data-inf-meta = "3" data-inf-error = "Bitte geben Sie eine Zeichenkette an" required="required" > </div> <div class="inf-input inf-input-text"> <label>E-Mail*</label> <input type="text" name="inf[1]" data-inf-meta = "1" data-inf-error = "Bitte geben Sie eine E-Mail-Adresse an" required="required" > <br/>(* nötige Angaben)</div> <div class="inf-submit"> <input type="submit" name="" value="Newsletter abonnieren"> </div> </div> </div> </form>`;
        content += `<form method="post" action="https://newsletter.infomaniak.com/external/submit" class="inf-form" target="_blank"><input type="email" name="email" style="display:none" /><input type="hidden" name="key" value="eyJpdiI6ImJZQlhaYUFadU9DR2ZhY01HWUY3eU85ZllUdklHVGM1OXVNV2xlUGNlOTg9IiwidmFsdWUiOiJIc0NBQjF3bzdyRUwrcjl5K3BaRE9HcW9tQlA1XC80XC9LZ013Zk9Td3FFams9IiwibWFjIjoiYjg2YTVjYmY0OTRkY2RhYmE5NDdiODY3NjFmYjJiNzc3ZjMxYzNhNWRlNGQ4ZjExZDU0YTIwMjhlY2FlNmFlMyJ9"><input type="hidden" name="webform_id" value="12954">
        <div class="inf-main_ab96c631d50e81c5f961bcbf25e49475"> <h4></h4> <span></span> <div class="inf-success" style="display:none"> <h4>Ihre Anmeldung wurde erfolgreich gespeichert!</h4> <p> <a href="#" class="inf-btn">&laquo;</a> </p> </div> <div class="inf-content"> <div class="inf-input inf-input-text"> <label>Vorname</label> <input type="text" name="inf[2]" data-inf-meta = "2" data-inf-error = "Bitte geben Sie eine Zeichenkette an" required="required" > </div> <div class="inf-input inf-input-text"> <label>Name</label> <input type="text" name="inf[3]" data-inf-meta = "3" data-inf-error = "Bitte geben Sie eine Zeichenkette an" required="required" > </div> <div class="inf-input inf-input-text"> <label>E-Mail</label> <input type="text" name="inf[1]" data-inf-meta = "1" data-inf-error = "Bitte geben Sie eine E-Mail-Adresse an" required="required" > </div> <div class="inf-submit"> <input type="submit" name="" value="Newsletter abonnieren"> </div> </div> </div> </form>`;
        break;
    }

    return content;
  }
}
