import Page from "./Page";
export default class Programm {
  constructor(data, page) {
    this.data = data;
    this.active_season = 0;
    this.page = page;
    this.programmation_data = data;
    //
    this.handler = {
      contentClick: this.onContentClick.bind(this),
      infoClick: this.onInfoClick.bind(this),
      close: this.onClose.bind(this),
    };
    //

    this.wrapper = document.querySelector(".programmWrapper");
    if (!this.wrapper) {
      this.wrapper = document.createElement("div");
      this.wrapper.className = "programmWrapper";
      page.appendChild(this.wrapper);
    }
    this.reset();
  }

  set programmation_data(data) {
    this.data = data;
    this.season = this.data[this.active_season];
    this.season_key = Object.keys(this.season)[0];
    this.color =
      this.season[this.season_key][0]["color"] === "#000000"
        ? `#ffe76d`
        : this.season[this.season_key][0]["color"];
    document.documentElement.style.setProperty("--main-color", this.color);
  }

  reset(factor) {
    // console.log("Programm");
    document.documentElement.style.setProperty("--main-color", this.color);
    this.wrapper.innerHTML = "";
    let open = false;
    this.season[this.season_key].forEach((item, i) => {
      const page = new Page(this.season, this.season_key, item, i, factor);
      this.wrapper.appendChild(page.div);
      // console.log(page.today, page.recorded);
      if (page.today < page.recorded && !open) {
        open = true;
        page.detail.classList.add("open", "forced");
        page.footer.classList.add("open", "forced");
        page.short.classList.add("open", "forced");
      }
    });

    const allContent = document.getElementsByClassName("content");
    Array.from(allContent).forEach((content) => {
      content.removeEventListener("click", this.handler.contentClick);
      content.addEventListener("click", this.handler.contentClick);
    });

    const allInfos = document.getElementsByClassName("footer");
    Array.from(allInfos).forEach((info) => {
      info.removeEventListener("click", this.handler.infoClick);
      info.addEventListener("click", this.handler.infoClick);
    });

    const allClosers = document.getElementsByClassName("closer");
    Array.from(allClosers).forEach((closer) => {
      closer.removeEventListener("click", this.handler.close);
      closer.addEventListener("click", this.handler.close);
    });

    // get height of page

    this.resize();
  }

  resize() {
    this.page.style.transition = `none`;
    if (this.page.classList.contains("show"))
      this.page.style.transform = `translateY(0px)`;
    else this.page.style.transform = `translateY(-${this.height}px)`;
    setTimeout(() => {
      this.page.style.transition = `transform 0.5s ease-out`;
    }, 10);
  }

  get height() {
    const height = this.page.getBoundingClientRect().height;
    const top = 300; //this.page.getBoundingClientRect().top;
    return height + top;
  }

  onContentClick(e) {
    let content = e.target.parentNode;
    console.log(content.tagName);
    if (content.tagName === "UL") content = e.target.parentNode.parentNode;
    content.querySelector(".short").classList.toggle("open");
    let footer = content.parentNode.querySelector(".footer");
    if (content.tagName === "UL")
      content.parentNode.parentNode.querySelector(".footer");
    footer.classList.toggle("open");

    //
    if (!footer.classList.contains("open")) {
      // console.log("content", content);
      content.parentNode.querySelector(".moreInfos").classList.remove("active");
    }
  }

  onInfoClick(e) {
    if (!e.target.classList.contains("active")) {
      this.resetInfos(e.target);
      e.target.parentNode.parentNode
        .querySelector(".moreInfos")
        .classList.toggle("active");
      e.target.classList.toggle("active");
    } else {
      this.resetInfos(e.target);
    }
  }
  onClose() {
    this.resetInfos(null);
  }

  resetInfos(target) {
    // console.log(target);
    const moreInfos = document.getElementsByClassName("moreInfos");
    Array.from(moreInfos).forEach((item) => {
      item.classList.remove("active");
    });

    const footers = document.getElementsByClassName("footer");
    Array.from(footers).forEach((item) => {
      const lis = item.getElementsByTagName("li");
      Array.from(lis).forEach((li) => {
        li.classList.remove("active");
      });
    });

    //show the proper text
    if (target) {
      const allInfos =
        target.parentNode.parentNode.getElementsByClassName("footer")[0]
          .children;
      Array.from(allInfos).forEach((info) => {
        info.parentNode.parentNode
          .querySelector(".moreInfos")
          .classList.remove("active");
        info.classList.remove("active");
        Array.from(
          info.parentNode.parentNode.querySelector(".moreInfos").children
        ).forEach((p) => {
          p.classList.remove("show");
        });
      });

      // //show the proper text
      // if (target) {
      const cl = target.getAttribute("data-p");
      target.parentNode.parentNode
        .querySelector(".moreInfos")
        .querySelector(`.${cl}`)
        .classList.add("show");
    }
  }
}
