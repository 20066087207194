import ArchivList from "./ArchivList";
import EventEmitter from "@onemorestudio/eventemitterjs";
export default class Archiv extends EventEmitter {
  constructor(data) {
    super();
    this.data = data;
    // //
    this.wrapper = document.querySelector(".programmWrapper");
    this.handler = {
      archivClick: this.onArchivClick.bind(this),
    };
  }

  reset(factor) {
    console.log("Archiv");
    this.wrapper.innerHTML = "";
    document.documentElement.style.setProperty("--main-color", "#ffe76d");
    this.data.forEach((item, i) => {
      if (Object.keys(item)[0] != "" && i != 0) {
        const archive = new ArchivList(
          Object.keys(item)[0],
          i,
          factor,
          this.data.length
        );
        this.wrapper.appendChild(archive.div);
        archive.addEventListener("click", this.handler.archivClick);
      }
    });
  }

  onArchivClick(e) {
    const year = e.target.dataset.year;
    this.emit("click", [year]);
  }
}
