export default class ScrollingText {
  constructor(container) {
    this.container = container;
    this.text = container.querySelector(".scrolling-text");
    this.textWidth = this.text.offsetWidth;
    this.containerWidth = this.container.offsetWidth;
    this.scrollSpeed = -1.5;
    this.scrollDirection = 1;
    this.scrollPosition = 0;
    this.setup();
  }

  async setup() {
    this.data = await this.loadJSON("./json/citations.json");
    this.textIndex = Math.floor(Math.random() * this.data.length);
    this.text.innerHTML = this.data[this.textIndex];
    this.textWidth = this.text.offsetWidth;
    this.scroll();
  }

  updateText() {
    this.textIndex++;
    if (this.textIndex >= this.data.length) {
      this.textIndex = 0;
    }
    this.text.innerHTML = this.data[this.textIndex];
    this.textWidth = this.text.offsetWidth;
  }

  scroll() {
    this.scrollPosition += this.scrollDirection * this.scrollSpeed;
    if (this.scrollPosition < -this.textWidth) {
      this.scrollPosition = this.containerWidth;
      this.updateText();
      console.log("reset", this.scrollPosition);
    }
    this.text.style.transform = `translateX(${this.scrollPosition}px)`;
    requestAnimationFrame(this.scroll.bind(this));
  }

  loadJSON(path) {
    return fetch(path).then((response) => response.json());
  }
}
