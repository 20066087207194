import EventEmitter from "@onemorestudio/eventemitterjs";
import RegularPage from "./RegularPage";
export default class Info extends EventEmitter {
  constructor(data, page) {
    super();
    this.data = data;
    // //
    this.wrapper = document.querySelector(".programmWrapper");
    if (!this.wrapper) {
      this.wrapper = document.createElement("div");
      this.wrapper.className = "programmWrapper";
      page.appendChild(this.wrapper);
    }
    this.handler = {
      onContentClick: this.onContentClick.bind(this),
    };

    //
    this.menu = [
      { name: "Contrapunkt", color: "#bad4f7", id: "35" },
      { name: "Kontakt", color: "#cccccc", id: "37" },
      { name: "Mitgliedschaft", color: "#c1e9e0", id: "38" },
      { name: "Newsletter", color: "#f2c087", id: "39" },
      { name: "Links", color: "#cccccc", id: "41" },
    ];
  }

  reset(factor) {
    console.log("Info");
    this.wrapper.innerHTML = "";
    //
    document.documentElement.style.setProperty("--main-color", "#bad4f7");

    this.data.forEach((item, i) => {
      const page = new RegularPage(item, this.data.length, i, factor);
      this.wrapper.appendChild(page.div);
    });

    const allContent = document.getElementsByClassName("content");
    Array.from(allContent).forEach((content) => {
      content.removeEventListener("click", this.handler.onContentClick);
      content.addEventListener("click", this.handler.onContentClick);
    });

    const form = document.getElementsByTagName("form")[0];
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      console.log("send form");
      // send form data with fetch
      const data = new FormData(form);
      fetch("https://www.contrapunkt-sg.ch/php/mail.php", {
        method: "POST",
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          if (json.message === "ok") {
            form.parentElement.innerHTML += `<div class="success">Vielen Dank für Ihre Nachricht.</div>`;
          } else {
            form.parentElement.innerHTML += `<div class="error">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.</div>`;
          }
        });
    });
  }

  onContentClick(e) {
    let content = e.target.parentNode;
    console.log(content.tagName);
    if (content.tagName === "UL") content = e.target.parentNode.parentNode;
    if (
      content.tagName != "FORM" &&
      content.tagName != "LABEL" &&
      content.querySelector(".short")
    )
      content.querySelector(".short").classList.toggle("open");
  }

  resize() {
    this.page.style.transition = `none`;
    if (this.page.classList.contains("show"))
      this.page.style.transform = `translateY(0px)`;
    else this.page.style.transform = `translateY(-${this.height}px)`;
    setTimeout(() => {
      this.page.style.transition = `transform 0.5s ease-out`;
    }, 10);
  }

  get height() {
    const height = this.page.getBoundingClientRect().height;
    const top = 300;
    return height + top;
  }
}
