export default class Page {
  constructor(season, key, item, i, factor) {
    this.div = document.createElement("div");
    this.div.className = "programm";
    this.div.style.zIndex = season[key].length - i;
    this.div.style.transitionDelay = `${(season[key].length - i) / factor}s`;
    const date = item["date"].split("-");
    this.recorded = new Date(item["date"]);
    this.today = new Date();
    if (recorded < today) {
      // this.div.style.backgroundColor = `#ffe76d`;
    }
    // console.log(
    //   item["muziker"].replaceAll("<div>", "").replaceAll("</div>", "<br>")
    // );
    this.div.innerHTML = `<div class="content" ><ul><li>${i + 1}</li><li>${
      item["titre"]
    }
                          <br/>${date[2]} ${date[1]} ${date[0]}</li></ul>
                          <div class="short">
                          <br/>${item["short"]}<br/>${item["date_txt"]}
                          <div class="detail">${item["text_2"]}</div>
                          </div>
                      </div>
                      <div class="moreInfos">
                          <p class="muziker">${item["muziker"]
                            .replaceAll("<div>", "")
                            .replaceAll("</div>", "<br>")}</p>
                          <p class="prog">${item["programm"]
                            .replaceAll("<div>", "")
                            .replaceAll("</div>", "<br>")}</p>
                          <p class="infos">${item["info"]
                            .replaceAll("<div>", "")
                            .replaceAll("</div>", "<br>")}</p>
                          <span class="material-icons closer">close</span>
                      </div>
                      <ul class="footer">
                          <li data-p="muziker">Musiker</li>
                          <li data-p="prog">Programm</li>
                          <li data-p="infos">Infos</li>
                      </ul>`;
    // div.innerHTML = `${item["titre"]}`;
    this.detail = this.div.querySelector(".detail");
    this.short = this.div.querySelector(".short");
    this.footer = this.div.querySelector(".footer");
  }
}
