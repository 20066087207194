import Utils from "./Utils";
import Programm from "./Programm";
import Info from "./Info";
import Archiv from "./Archiv";
import Header from "./Header";
import ScrollingText from "./ScrollingText";

export default class App {
  constructor() {
    this.factor = 20;
    this.active_season = 0;
    this.pages = document.querySelector("#pages");
    // prepare pages
    this.init();
  }

  async init() {
    this.data = await Utils.loadJSON(
      `https://www.contrapunkt-sg.ch/php/api.php?JSON&type=all`
    );
    this.infoData = await Utils.loadJSON(
      `https://www.contrapunkt-sg.ch/php/api.php?type=allRegular&JSON`
    );
    this.season = this.data[this.active_season];
    this.season_key = Object.keys(this.season)[0];

    this.header = new Header(
      this.pages,
      this.season,
      this.season_key,
      this.factor
    );
    this.programm = new Programm(this.data, this.pages);
    this.archiv = new Archiv(this.data, this.pages);
    this.info = new Info(this.infoData, this.pages);

    const scrollingText = new ScrollingText(document.querySelector("#bandeli"));
    this.initListeners();
  }

  initListeners() {
    this.handler = {
      logoClick: this.onLogoClick.bind(this),
      // contentClick: this.onContentClick.bind(this),
      // infoClick: this.onInfoClick.bind(this),
      // close: this.onClose.bind(this),
      archiveYearClick: this.onArchiveYearClick.bind(this),
      // infoChapterClick: this.onInfoChapterClick.bind(this),
      menuClick: this.onMenuClick.bind(this),
    };
    const logo = document.getElementById("logo");
    logo.addEventListener("click", this.handler.logoClick);

    this.header.addEventListener("click", this.handler.menuClick);

    this.archiv.addEventListener("click", this.handler.archiveYearClick);

    this.info.addEventListener("click", this.handler.infoChapterClick);

    window.addEventListener("resize", () => {
      this.programm.resize();
    });
  }

  onArchiveYearClick(e) {
    this.programm.active_season = e;
    this.programm.programmation_data = this.data;
    this.programm.reset(this.factor);
  }

  reset() {
    const allShort = document.getElementsByClassName("short");
    Array.from(allShort).forEach((short) => {
      if (!short.classList.contains("forced")) short.classList.remove("open");
    });
    const allFooters = document.getElementsByClassName("footer");
    Array.from(allFooters).forEach((footer) => {
      if (!footer.classList.contains("forced")) footer.classList.remove("open");
    });
  }

  //   listeners
  onLogoClick(e) {
    e.target.parentNode.classList.toggle("top_left");
    this.toggleVisibility();
    this.reset();
  }

  toggleVisibility() {
    this.pages.classList.toggle("show");
    if (this.pages.classList.contains("show"))
      this.pages.style.transform = `translateY(0px)`;
    else
      this.programm.page.style.transform = `translateY(-${this.programm.height}px)`;
  }

  onMenuClick(e) {
    if (e.target.nodeName === "LI") {
      const lis = e.target.parentNode.children;
      Array.from(lis).forEach((li) => {
        li.classList.remove("active");
      });
      e.target.classList.add("active");
      switch (e.target.dataset.menu) {
        case "programm":
          this.programm.active_season = this.active_season;
          this.programm.programmation_data = this.data;
          this.programm.reset(this.factor);
          break;
        case "archiv":
          this.archiv.reset(this.factor);
          break;
        case "info":
          this.info.reset(this.factor);
          break;
      }
    }
  }
}
