import EventEmitter from "@onemorestudio/eventemitterjs";

export default class Header extends EventEmitter {
  constructor(pages, s, k, factor) {
    super();
    this.handler = {
      menuClick: this.onMenuClick.bind(this),
    };
    const season = document.createElement("div");
    season.className = "season";
    season.innerHTML = `<ul><li class="active" data-menu="programm">${k}</li><li data-menu="archiv">Archiv</li><li data-menu="info">Info</li></ul>`;
    season.style.zIndex = 0;
    season.style.transitionDelay = s[k].length / factor + "s";
    pages.appendChild(season);

    const menu = season.getElementsByTagName("ul")[0];
    menu.addEventListener("click", this.handler.menuClick);
  }
  onMenuClick(e) {
    this.emit("click", [e]);
  }
}
